<!-- @Author: Yu_Bo 2023-09-06 11:33:31-->
<template>
    <div>
        <el-table :data="list" v-loading="loading" style="width: 100%" key="img" header-cell-class-name="table-header" cell-class-name="cell-class">
            <template slot="empty">
                <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
            </template>
            <el-table-column type="index" label="序号" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.$index + limit*(page -1) + 1   }}
                </template>
            </el-table-column>
            <el-table-column prop="name" label="背景名称"></el-table-column>
            <el-table-column prop="image" label="图片缩略图">
                <template slot-scope="scope">
                    <el-image style="width:auto; height: 50px" :src="scope.row.image" :preview-src-list="srcList">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.type==1">图片</span>
                    <span v-if="scope.row.type==2">视频</span>
                </template>
            </el-table-column>
            <el-table-column label="比例">
                <template slot-scope="scope">
                    <span v-if="scope.row.ratio==1">16:9</span>
                    <span v-if="scope.row.ratio==2">9:16</span>
                </template>
            </el-table-column>
            <el-table-column prop="materialCategory.name" label="分类"></el-table-column>
            <el-table-column label="状态" width="100">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
            <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="editBackgroun(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click="delBackgroun(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="material" @close='closeBackgroun' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否删除背景，删除后将不可恢复</div>
            </div>
        </point-out>
        <!-- 添加/编辑 -->
        <add-edit ref="addedit" :list="classifyOptions" @affirm="getList"></add-edit>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import AddEdit from './add_edit'// 添加/编辑
export default {
    components: { PointOut, EmptyData, AddEdit },
    props: {
        list: Array,
        loading: Boolean,
        srcList: Array,
        classifyOptions: Array,
        limit: Number,
        page: Number,
    },
    data() {
        return {
            delId: null,
        };
    },

    computed: {},

    watch: {},

    created() {

    },

    mounted() {

    },

    methods: {
        // 编辑
        editBackgroun(info) {
            var obj = JSON.parse(JSON.stringify(info))
            this.$refs.addedit.openDialog(obj)
        },
        // 删除
        delBackgroun(info) {
            this.delId = info.id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeBackgroun() {
            if (this.delId != null) {
                this.$materialApi.backgroupListDel(this.delId).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.$emit('getList')
                        this.delId = null
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        getList() {
            this.$emit('getList')
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var params = {
                id: info.id,
                status: val
            }
            this.$materialApi.backgroupListChange(params).then(res => {
                if (res.code == 1000) {
                    this.list[index].status = val
                    this.$succMsg(res.message)
                } else {
                    this.list[index].status = val == 1 ? 2 : 1
                    this.$errMsg(res.message)
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped></style>