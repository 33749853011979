<!-- @Author: Yu_Bo 2023-09-05 18:28:44-->
<template>
    <div class="backgroun_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <div class="nav_txt" :class="navIndex==1?'nav_active':''" @click="navBtn(1)">图片列表</div>
            <div class="nav_txt" :class="navIndex==2?'nav_active':''" @click="navBtn(2)">视频列表</div>
        </div>
        <!--  -->
        <div class="backgroun_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addBackgroun" size="small" icon="el-icon-plus">添加背景</el-button>
                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索背景名称"></el-input>
                    <el-select v-model="ratio" size="small" @change="searchBtn" :clearable="true" placeholder="请选择比例">
                        <el-option v-for="item in ratioList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="classify" size="small" @change="searchBtn" :clearable="true" placeholder="请选择分类">
                        <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="status" size="small" @change="searchBtn" :clearable="true" placeholder="请选择状态">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- 图片列表 -->
            <div class="main_table" v-if="navIndex == 1">
                <img-list ref="img" :loading="loading" :list="list" :page="page" :limit="limit" :classifyOptions="classifyOptionsUp" :srcList="srcList" @getList="getList"></img-list>
            </div>
            <div class="main_table" v-if="navIndex == 2">
                <video-list ref="video" :loading="loading" :list="list" :page="page" :limit="limit" :classifyOptions="classifyOptionsUp" :srcList="srcList" @getList="getList"></video-list>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 添加/编辑 -->
        <add-edit ref="addedit" :list="classifyOptionsUp" @affirm="getList"></add-edit>
    </div>
</template>

<script>
import ImgList from '../components/background/img_list'//图片列表
import VideoList from '../components/background/video_list'//视频列表
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddEdit from '../components/background/add_edit'// 添加/编辑
export default {
    components: { ImgList, VideoList, PagingPage, AddEdit },
    props: {},
    data() {
        return {
            navIndex: 1,
            // 名称
            name: '',
            // 分类
            classify: '',
            classifyOptions: [],
            classifyOptionsUp: [],
            // 状态
            status: '',
            statusOptions: [
                { value: 1, label: "开启" },
                { value: 2, label: "关闭" },
            ],
            //比例
            ratio: '',
            ratioList: [],
            list: [],
            total: '',
            page: 1,
            limit: 10,
            srcList: [],
        };
    },

    computed: {},

    watch: {},

    created() {
        this.getCategory()
        this.getList()
    },

    mounted() { },

    methods: {
        // 分类
        getCategory() {
            this.$materialApi.backgroupCategory().then(res => {
                if (res.code == 1000) {
                    this.classifyOptions = res.result.cat_list
                    this.ratioList = res.result.radio
                }
            })
            this.$materialApi.backgroupCategory().then(res => {
                if (res.code == 1000) {
                    this.classifyOptionsUp = res.result.cat_list
                }
            })
        },
        
        // 数据列表
        getList() {
            this.loading = true
            var params = {
                type: this.navIndex,
                page: this.page,
                limit: this.limit,
                ratio: this.ratio,
                name: this.name,
                status: this.status,
                category_id: this.classify,
            }
            this.$materialApi.backgroupList(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                    let arr = []
                    this.list.forEach(ele => {
                        arr.push(ele.image)
                    })
                    this.srcList = arr
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 切换
        navBtn(val) {
            this.navIndex = val
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 筛选搜索
        searchBtn() {
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 添加
        addBackgroun() {
            this.$refs.addedit.openDialog('', this.navIndex)
        },
        // 
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
    }
}
</script>
<style lang='scss' scoped>
.backgroun_vue {
    width: 100%;
    .nav_txt {
        cursor: pointer;
        margin-right: 40px;
    }
    .nav_active {
        color: #2e4bf2;
    }
    .backgroun_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>